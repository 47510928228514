import {ScrollWrapper} from "../../components/ScrollWrapper";
import {ParallaxProvider} from "react-scroll-parallax";
import {DepositInfoHeader} from "../deposit-info/components/header";
import {Footer} from "../../components";
import React from "react";

import {useTranslation} from "react-i18next";

import './style.scss'

export const AccountRemovePage = () => {
    return (
        <ScrollWrapper>
            <ParallaxProvider>
                <DepositInfoHeader/>

                <RemoveAccountInfo/>

                <Footer/>
            </ParallaxProvider>
        </ScrollWrapper>
    )
}

const description = [
    "remove-account.description.0",
    "remove-account.description.1",
    "remove-account.description.2",
    "remove-account.description.3",
]

const typesDescription = [
    "remove-account.data-types-description.0",
    "remove-account.data-types-description.1",

]

const RemoveAccountInfo = () => {
    const {t, i18n} = useTranslation()


    return (<div className={'remove-account-wrapper'}>
        <div className={'remove-account-container'}>
            <span className={'remove-account-header'}>
                {t('remove-account.header')}
            </span>
            <ol>
                {description.map(desc =>
                    <li>{t(desc)}</li>
                )}
            </ol>
            <span className={'remove-account-header'}>
                {t('remove-account.data-types-header')}
            </span>
            <ul>
                {typesDescription.map(desc =>
                    <li dangerouslySetInnerHTML={{__html: t(desc)}}/>
                )}
            </ul>
            <span className={'remove-account-header'}>
                {t('remove-account.storage-header')}
            </span>
            <span>
                {t('remove-account.storage-description')}
            </span>
        </div>

    </div>)
}
