import React from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Grid} from "@material-ui/core";
import {LangSelect} from "../../../../components/LangSelect";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        [theme.breakpoints.down("sm")]: {
            root: {
                maxWidth: "100%",
                margin: "0 auto",
                padding: "32px 16px",
                display: "flex",
                flexWrap: "wrap",
            },
            copyrightBlock: {
                marginBottom: 16,
            },
            myFont: {
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: 13,
                color: "white",
            },
        },
        [theme.breakpoints.between("md", "xl")]: {
            root: {
                maxWidth: 1280,
                margin: "0 auto",
                boxSizing: "border-box",
                position: "relative",
                padding: "48px",
            },
            myFont: {
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: 15,
                color: "white",
            },
        },
    })
);

export const DepositInfoHeader = () => {
    const classes = useStyles({})

    return (
        <Grid container className={classes.mainRoot}>
            <Grid container justify={'space-between'} className={classes.root}>
                <a href={'https://www.bcc.kz/juniorbank/'}>
                    <img style={{width: 224}} src={`${process.env.PUBLIC_URL}/junior-bank-logo.svg`}/>
                </a>
                <LangSelect/>
            </Grid>
        </Grid>
    )
}
