class LocalStorageServiceImpl {
    setItem<T>(key: string, value: string) {
        localStorage.setItem(key, value);
    }

    getItem<T>(key: string): string | null {
        return localStorage.getItem(key);
    }
}

const LocalStorageService = new LocalStorageServiceImpl()
export {LocalStorageService};

