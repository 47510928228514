import {useLocation} from "react-router-dom";
import {useLayoutEffect} from "react";

interface IScrollWrapper {
    children: JSX.Element;
}

export const ScrollWrapper = ({ children }: IScrollWrapper) => {
    const location = useLocation();

    useLayoutEffect(() => {
        window.scrollTo({ top: 0 });
    }, [location.pathname]);
    return children;
};
