import React from "react";
import {useTranslation} from "react-i18next";
import './style.scss'

export const Gamefacation = () => {

    const {t, i18n} = useTranslation()

    return <div className={'gamefacation-wrapper'}>
        <div className={'gamefacation-container'}>

            <div className={'gamefacation-info'}>
                <div className={'game-title'}>{t('game.title')}</div>
                <div className={'game-text'} dangerouslySetInnerHTML={{__html: t('game.description')}}/>
            </div>

            <div className={'gamefacation-img-container'}>
                <img className={'gamefacation-img'} src={process.env.PUBLIC_URL + `/numella/${i18n.language}.gif`}/>

            </div>

        </div>

    </div>
}