import React from "react";
import {useTranslation} from "react-i18next";

export const SecondBanner = () => {
    const {t, i18n} = useTranslation()

    return (
        <div className={'second-banner-container'}>
            <div className={'second-banner-title'} dangerouslySetInnerHTML={{__html: t('parents-task.second-banner')}}/>
            <img src={process.env.PUBLIC_URL + `/parents-task/phone-${i18n.language}.png`}/>
        </div>
    )
}
