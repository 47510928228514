import {Box, Table, TableBody, TableCell, TableRow} from "@material-ui/core";
import React from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";

const useStylesDepositConditions = makeStyles((theme: Theme) =>
    createStyles({
        [theme.breakpoints.down("sm")]: {
            th: {
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: 14,
                color: "#898989",
            },
            td: {
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: 14,
                color: "black",
                "& ul":{
                    paddingLeft: '20px'
                }
            },
            TableW: {
                widthh: "100%",
                margin: '20px 10px',

            },
            TableWW: {
                paddingTop: 12,
                "& span": {
                    fontSize: 14,
                },
            },
            h2:{
                fontSize: '40px',
                lineHeight: '40px',
                paddingBottom: '30px',
                margin: 0,
                borderBottom: '1px solid #C4C4C4'
            },
        },
        [theme.breakpoints.between("sm", "xl")]: {
            tbody: {},
            th: {
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: 16,
                color: "#898989",
                padding: 14,
            },
            td: {
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: 16,
                color: "black",
                padding: 14,

                "& span": {
                    fontSize: 14,
                    color: "#5B5B5B",
                },
                "& ul": {
                    margin: 0,
                    paddingLeft: '26px'
                }
            },
            TableW: {
                width: "70%",
                margin: '68px auto',
            },
            TableWW: {
                paddingTop: 12,
            },
            h2:{
                fontSize: '40px',
                lineHeight: '40px',
                paddingBottom: '30px',
                margin: 0,
                borderBottom: '1px solid #C4C4C4'
            },

        },
    })
);

const rows = [
    {
        code: 'deposit_info.condition_item.item1.text',
        value: 'deposit_info.condition_item.item1.value',
    },
    {
        code: 'deposit_info.condition_item.item2.text',
        value: 'deposit_info.condition_item.item2.value',
    },
    {
        code: 'deposit_info.condition_item.item3.text',
        value: 'deposit_info.condition_item.item3.value',
    },
    {
        code: 'deposit_info.condition_item.item4.text',
        value: 'deposit_info.condition_item.item4.value',
    },
    {
        code: 'deposit_info.condition_item.item5.text',
        value: 'deposit_info.condition_item.item5.value',
    },
    {
        code: 'deposit_info.condition_item.item6.text',
        value: 'deposit_info.condition_item.item6.value',
    },
    {
        code: 'deposit_info.condition_item.item7.text',
        value: 'deposit_info.condition_item.item7.value',
    },
    {
        code: 'deposit_info.condition_item.item8.text',
        value: 'deposit_info.condition_item.item8.value',
    },
    {
        code: 'deposit_info.condition_item.item9.text',
        value: 'deposit_info.condition_item.item9.value',
    },
    {
        code: 'deposit_info.condition_item.item10.text',
        value: 'deposit_info.condition_item.item10.value',
    },
];


export const DepositConditions = () => {
    const [t] = useTranslation()

    const classes = useStylesDepositConditions({});
    return (
        <Box className={classes.TableW}>
            <h2 className={classes.h2}>
                {t('deposit_info.title')}
            </h2>
            <Table>
                <TableBody className={classes.tbody}>
                    {rows.map((m, i) => (
                        <TableRow key={i}>
                            <TableCell className={classes.th}>
                                <div dangerouslySetInnerHTML={{__html: t(m.code)}}/>
                            </TableCell>
                            <TableCell className={classes.td}>
                                <div dangerouslySetInnerHTML={{__html: t(m.value)}}/>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Box>
    );
};