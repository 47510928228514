import {MenuItem, Select} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {LocalStorageService} from "../local-storage.service";
import i18next from "i18next";

export const LangSelect = () => {
    const [language, setLanguage] = useState<string>('')

    useEffect(() => {
        const lang = LocalStorageService.getItem('lang')
        if (lang) {
            changeLanguage(lang)
        } else setLanguage('kz')
    }, [])


    const changeLanguage = (language: string) => {
        const i18n = i18next;
        i18n.changeLanguage(language).then(() => {
            LocalStorageService.setItem('lang', language)
            setLanguage(language)
        });
    };
    return (
        <Select value={language} onChange={(e) => changeLanguage(e.target.value as string)}>
            <MenuItem value={'kz'}>ҚАЗ</MenuItem>
            <MenuItem value={'ru'}>РУС</MenuItem>
        </Select>
    )
}