import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import ReactGA from "react-ga";
import {Parallax} from "react-scroll-parallax";
import {Grid} from "@material-ui/core";
import {BccTypography} from "../../../../components/BccComponents";
import React, {useEffect, useState} from "react";
import {CSSTransition, SwitchTransition} from "react-transition-group";
import './style.scss'
import classNames from "classnames";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        [theme.breakpoints.down("sm")]: {
            rootOutside: {
                position: "relative",
                "& > img": {
                    display: "none",
                },
            },
            root: {
                position: "relative",
                maxWidth: "100%",
                margin: "0 auto",
                zIndex: 1,
                marginTop: 32,
                padding: "0 16px 32px",
                color: "white",
            },
            title: {
                marginBottom: 32,
                fontSize: 28,
                fontWeight: "bold",
                lineHeight: 1,
            },
            lOut: {
                flexWrap: "nowrap",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
            },
            qr: {
                "& > img": {
                    height: 70,
                },
            },
            imgBlock: {
                gridArea: "a",
                width: "60%",
                position: "relative",
                zIndex: 4,
                textAlign: "right",
                "& > img:first-child": {
                    position: "relative",
                    zIndex: 3,
                    width: "100%",
                    maxHeight: 500,
                },
                "& > figure": {
                    display: "none",
                },
            },
            mobileLinks: {
                marginRight: 24,
                "& > a:last-child": {
                    marginBottom: 0,
                },
                "& > a": {
                    display: "block",
                    marginBottom: 12,
                    width: 120,
                    "& > img": {
                        display: "block",
                        width: 120,
                    },
                },
            },
            as: {
                marginBottom: 10,
            },
            textBlock: {width: "100%", padding: "32px 16px 0", textAlign: "right"},
            container: {
                position: "relative",
                zIndex: 3,
                backgroundColor: "#2D72E5",
                borderRadius: 24,
                width: "100%",
                flexWrap: "nowrap",
                display: "flex",
                alignItems: "center",
            },
            storesApp: {
                flexWrap: "nowrap",
                justifyContent: "flex-end",
            },
            appStore: {
                marginRight: 22,
                "& img": {
                    width: "100%",
                },
            },
            googleMarker: {
                "& img": {
                    width: "100%",
                },
            },
            headerBgPart: {
                position: "absolute",
                right: 0,
                bottom: 0,
                zIndex: 0,
                "& > figure": {display: "none", margin: 0},
                "& > img": {
                    width: "100%",
                },
            },
            headerBgPart1: {
                zIndex: 0,
                left: 0,
                top: 0,
            },
            headerBgPart2: {
                display: 'none',

                left: 50,
                zIndex: 2,
                bottom: -30,
            },
            headerBgPart3: {
                display: 'none',
                top: 0,
                right: 0,
                zIndex: 4,
            },
            headerBgPart4: {
                display: 'none',

                right: "30%",
                bottom: -30,
            },
            qrMob: {
                display: "none",
            },
        },
        [theme.breakpoints.between("md", "xl")]: {
            rootOutside: {
                position: "relative",
            },
            qrMob: {
                display: "none",
            },
            headerBgPart: {
                position: "absolute",
                right: 0,
                bottom: 0,
                zIndex: 0,
                "&>figure": {margin: 0},
                "& > img": {
                    width: "100%",
                },
            },
            headerBgPart1: {
                zIndex: 0,
                left: 0,
                top: 0,
            },
            headerBgPart2: {

                left: 50,
                zIndex: 2,
                bottom: -30,
            },
            headerBgPart3: {
                display: 'none',
                top: 0,
                right: 0,
                zIndex: 2,
            },
            headerBgPart4: {
                right: "30%",
                bottom: -30,
            },
            root: {
                position: "relative",
                maxWidth: 1280,
                boxSizing: "border-box",
                margin: "200px auto",
                zIndex: 1,
                padding: "0 56px 64px",
                color: "white",
            },
            title: {
                marginBottom: 16,
            },
            subTitle: {
                marginBottom: 32,
            },
            imgBlock: {
                width: "50%",
                zIndex: 4,
                height: '100%',
                position: 'absolute',
                right: 0,
                "& > img:first-child": {
                    position: "absolute",
                    top: 0,
                    zIndex: 3,
                    right: 40,
                    height: '100%',
                },
                "& > figure": {
                    position: "absolute",
                    right: 295,
                    top: -70,
                },
            },
            lOut: {
                alignItems: "center",
                "& > div": {
                    width: "auto",
                },
            },
            qr: {
                "& > img": {},
            },
            mobileLinks: {marginRight: 50},
            textBlock: {width: "50%", padding: "100px 0 180px 80px"},
            container: {
                position: "relative",
                zIndex: 3,
                backgroundColor: "#2D72E5",
                borderRadius: 24,
                width: "100%",
            },
            appStore: {
                marginRight: 22,
            },
            as: {
                marginBottom: 25,
                display: "inline-block",
            },
        },
        [theme.breakpoints.down("xs")]: {
            link: {
                maxWidth: 100,
            },
            container: {
                flexDirection: "column",
                position: 'relative',
                marginBottom: '85%'
            },
            textBlock: {
                textAlign: "center",
                height: 500,
            },
            lOut: {
                display: "none",
            },
            imgBlock: {
                width: "100%",
                position: 'absolute',
                top: '40%',
                "& > img": {
                    display: "none",
                },
            },
            qrMob: {
                display: 'flex',
                textAlign: "center",
                "& > img": {
                    display: "block",
                    margin: "0 auto",
                },

                "& > span": {
                    display: "inline-block",
                },
            },
        },
        link: {
            color: "#2D72E5",
            display: "block",
            textDecoration: "underline",
            cursor: "pointer",
            maxWidth: '100%',
            "& > img": {
                width: "100%",
            },
        },
    })
);

export const Deposit = () => {
    const classes = useStyles({});
    const [t] = useTranslation()

    const onClickAS = (e: any) => {
        e.preventDefault();


    };

    const onClickGP = (e: any) => {
        ReactGA.event({
            category: "Juniorbank_downloawd_GooglePlay",
            action: "juniorbank_googleplay",
        });
        window.open(
            "https://play.google.com/store/apps/details?id=kz.bcc.juniorbank&hl=ru",
            "_blank"
        );
    };
    return (
        <div className={classes.rootOutside}>
            <div className={`${classes.headerBgPart} ${classes.headerBgPart1}`}>
                <Parallax translateY={[120, 0]}>
                    <img src={process.env.PUBLIC_URL + "/appBgPart1.svg"} alt="app"/>
                </Parallax>
            </div>
            <div className={`${classes.headerBgPart} ${classes.headerBgPart2}`}>
                <Parallax translateY={[120, 0]}>
                    <img src={process.env.PUBLIC_URL + "/appBgPart2.svg"} alt="app"/>
                </Parallax>
            </div>
            <div className={`${classes.headerBgPart} ${classes.headerBgPart3}`}>
                <Parallax translateY={[120, -40]}>
                    <img src={process.env.PUBLIC_URL + "/appBgPart5.svg"} alt="app"/>
                </Parallax>
            </div>
            <div className={`${classes.headerBgPart} ${classes.headerBgPart4}`}>
                <Parallax translateY={[120, 0]}>
                    <img src={process.env.PUBLIC_URL + "/appBgPart4.svg"} alt="app"/>
                </Parallax>
            </div>
            <div className={classes.root}>
                <Grid
                    container
                    justify="space-between"
                    wrap="nowrap"
                    className={classes.container}
                >
                    <Grid item className={classes.textBlock}>
                        <BccTypography block type="h1" className={classes.title}>
                            {t('deposit_info.how_open.title')}
                        </BccTypography>
                        <BccTypography block type="p2l" className={classes.subTitle}>
                            {t('deposit_info.how_open.description')}
                        </BccTypography>
                        <Grid
                            container
                            justify="flex-start"
                            direction="row"
                            className={classes.lOut}
                            wrap="nowrap"
                        >
                            <Grid item className={classes.mobileLinks}>
                            <span className={`${classes.link} ${classes.as}`} onClick={(e: any) => onClickAS(e)}>
                                <img src={process.env.PUBLIC_URL + "/as.svg"}/>
                            </span>
                                <span
                                    className={classes.link}
                                    onClick={(e: any) => onClickGP(e)}
                                >
                            <img src={process.env.PUBLIC_URL + "/gp.svg"}/>
                            </span>
                            </Grid>
                            <Grid item className={classes.qr}>
                                <img src={process.env.PUBLIC_URL + "/qr.png"}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.imgBlock}>

                        <GifContainer/>


                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

const GifContainer = () => {
    const {t, i18n} = useTranslation()
    const [selectedGif, setSelectedGif] = useState<number>(0)
    const [sliderDirection, setSliderDirection] = useState<'back' | 'forward'>('back')
    const [isMount, setIsMount] = useState<boolean>(false)
    const classes = useStyles({})

    useEffect(() => {
        if (isMount) {
            if (sliderDirection === 'back') {
                setSelectedGif(prevState => --prevState)
            } else {
                setSelectedGif(prevState => ++prevState)
            }
        }
        setIsMount(true)
    }, [sliderDirection])

    const onClickAS = (e: any) => {
        e.preventDefault();


    };

    const onClickGP = (e: any) => {
        ReactGA.event({
            category: "Juniorbank_downloawd_GooglePlay",
            action: "juniorbank_googleplay",
        });
        window.open(
            "https://play.google.com/store/apps/details?id=kz.bcc.juniorbank&hl=ru",
            "_blank"
        );
    };

    const gif1Text = t('deposit_info.how_open.gif1')
    const gif2Text = t('deposit_info.how_open.gif2')


    return (
        <div className={'main-container'}>

            <img className={classNames('chevron', 'left', selectedGif === 0 ? 'disabled' : '')}
                 onClick={() => {
                     if (selectedGif !== 0)
                         setSliderDirection(prevState => {
                             if (prevState === 'forward') {
                                 return 'back';
                             } else {
                                 setSelectedGif(prevState => prevState - 1)
                                 return 'forward'
                             }
                         })
                 }
                 }

                 src={`${process.env.PUBLIC_URL}/chevron-left.svg`}/>

            <div>
                <SwitchTransition>
                    <FadeTransition a={sliderDirection === 'forward'} key={selectedGif}>
                        <div className={'button-container'}>
                            {selectedGif === 0 ?
                                <>
                                    <div dangerouslySetInnerHTML={{__html: gif1Text}} className={'gif-text'}/>
                                    <img src={process.env.PUBLIC_URL + `/junior-1-${i18n.language}.gif`}/>
                                    <div dangerouslySetInnerHTML={{__html: gif1Text}} className={'gif-text-mob'}/>
                                </> :
                                <>
                                    <div dangerouslySetInnerHTML={{__html: gif2Text}} className={'gif-text'}/>
                                    <img src={process.env.PUBLIC_URL + `/junior-2-${i18n.language}.gif`}/>
                                    <div dangerouslySetInnerHTML={{__html: gif2Text}} className={'gif-text-mob'}/>
                                </>
                            }


                        </div>
                    </FadeTransition>
                </SwitchTransition>


                <Grid item container justify={'space-between'} className={classes.qrMob}>
                    <div className={`${classes.link} link`} onClick={(e: any) => onClickAS(e)}>
                        <img src={process.env.PUBLIC_URL + "/as.svg"}/>
                    </div>
                    <div className={`${classes.link} link`} onClick={(e: any) => onClickGP(e)}>
                        <img src={process.env.PUBLIC_URL + "/gp.svg"}/>
                    </div>
                </Grid>
            </div>

            <img
                className={selectedGif === 1 ? 'chevron right disabled' : 'chevron right'}
                onClick={() => {
                    if (selectedGif !== 1)
                        setSliderDirection(prevState => {
                            if (prevState === 'back') {
                                return 'forward';
                            } else {
                                setSelectedGif(prevState => prevState + 1)
                                return 'back'
                            }
                        })
                }}
                src={`${process.env.PUBLIC_URL}/chevron-right.svg`}
            />

        </div>

    )
}


export const FadeTransition = (props: any) => (
    <CSSTransition
        {...props}
        classNames={{
            enter: props.a ? 'fade-enter' : "fade1-enter",
            enterActive: props.a ? 'fade-enter-active' : "fade1-enter-active",
            exit: props.a ? "fade-exit" : "fade1-exit"
        }}
        addEndListener={(node, done) => {
            node.addEventListener("transitionend", done, false);
        }}
    />
);
