import {DepositInfoHeader} from "../deposit-info/components/header";
import React from "react";
import {ScrollWrapper} from "../../components/ScrollWrapper";
import './style.scss'
import {FirstBanner} from "./components/first-banner";
import {SecondBanner} from "./components/second-banner";
import {Benefits} from "./components/benefits";
import {Footer} from "../../components";

export const IphonePromotionPage = () => {


    return (
        <ScrollWrapper>
            <div className={'promotion-container'}>
                <DepositInfoHeader/>
                <FirstBanner/>
                <SecondBanner/>
                <Benefits/>
                <Footer/>
            </div>
        </ScrollWrapper>
    )
}
