import React from "react";
import {useTranslation} from "react-i18next";

export const SecondBanner = () => {
    const {t, i18n} = useTranslation()

    return (
        <>
            <div className={'promotion-container'}>
                {t('iphone-promotion-page.benefit-title')}
            </div>
            <div className={'second-banner-container'}>
                <div className={'second-banner-title'}
                     dangerouslySetInnerHTML={{__html: t('iphone-promotion-page.second-banner')}}/>
                <img src={process.env.PUBLIC_URL + '/iphone-promotion/girl.png'}/>
                <div className={'gradient'}/>
            </div>
        </>

    )
}
