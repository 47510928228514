import React from "react";
import {useTranslation} from "react-i18next";

export const SecondFinLessonsBanner = () => {
    const {t, i18n} = useTranslation()

    return (
        <div className={'second-banner-container'}>
            <div className={'second-banner-title'}
                 dangerouslySetInnerHTML={{__html: t('financial-lessons.second-banner')}}/>
            <img src={process.env.PUBLIC_URL + `/financial-lessons/phone-${i18n.language}.png`}/>
        </div>
    )
}
