import React from "react"
import {ScrollWrapper} from "../../components/ScrollWrapper";
import {DepositInfoHeader} from "../deposit-info/components/header";
import {Footer} from "../../components";
import './style.scss'
import {FirstFinLessonsBanner} from "./components/first-banner";
import {SecondFinLessonsBanner} from "./components/second-banner";
import {useTranslation} from "react-i18next";

export const FinancialLessonsPage = () => {


    return (
        <ScrollWrapper>
            <div className={'financial-lessons-container'}>
                <DepositInfoHeader/>
                <FirstFinLessonsBanner/>
                <SecondFinLessonsBanner/>
                <ToLessonsButton/>

                <Footer/>
            </div>
        </ScrollWrapper>
    )
}


const ToLessonsButton = () => {

    const {t, i18n} = useTranslation()

    return <a href={`https://junior-fin-lessons.bcc.kz/?lang=${i18n.language}`} className={'to-lessons-button'}>
        {t('financial-lessons.start')}

        <img className={'hand-icon'} src={process.env.PUBLIC_URL + '/financial-lessons/hand.svg'}/>
    </a>
}
