import React from "react";
import {Box, Grid, Table, TableBody, TableCell, TableRow,} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import Tab from "./Tab";
import {BccCollapseDetails, BccCollapsePanel, BccCollapseTitle, BccTypography,} from "../BccComponents";
import ReactGA from "react-ga";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {useTranslation} from "react-i18next";

const useStylesTarifs = makeStyles((theme: Theme) =>
    createStyles({
      [theme.breakpoints.down("sm")]: {
        th: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 14,
        color: "#898989",
      },
      td: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 14,
        color: "black",
      },
      TableW: {
        widthh: "100%",
      },
      TableWW: {
        paddingTop: 12,
        "& span": {
          fontSize: 14,
        },
      },
    },
    [theme.breakpoints.between("sm", "xl")]: {
      th: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 16,
        color: "#898989",
      },
      td: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 16,
        color: "black",
        "& span": {
          fontSize: 14,
          color: "#5B5B5B",
        },
      },
      TableW: {
        widthh: "70%",
      },
      TableWW: {
        paddingTop: 12,
      },
    },
  })
);

const Benefits = () => {
  const classes = useStylesTarifs({});
  const {t} = useTranslation()
  const rows = [
    {
      code: t('block_10.tab_1.item.tab_item_title_1'),
      value: t('block_10.tab_1.item.tab_item_title_1'),
    },
    {
      code: t('block_10.tab_1.item.tab_item_title_2'),
      value: t('block_10.tab_1.item.tab_item_text_2'),
    },
    {
      code: t('block_10.tab_1.item.tab_item_title_3'),
      value: t('block_10.tab_1.item.tab_item_text_3'),
    },
    {
      code: t('block_10.tab_1.item.tab_item_title_4'),
      value: t('block_10.tab_1.item.tab_item_text_4'),
    },
    {
      code: t('block_10.tab_1.item.tab_item_title_5'),
      value: t('block_10.tab_1.item.tab_item_text_5'),
    },
    {
      code: t('block_10.tab_1.item.tab_item_title_6'),
      value: t('block_10.tab_1.item.tab_item_text_6'),
    },
    {
      code: t('block_10.tab_1.item.tab_item_title_7'),
      value: t('block_10.tab_1.item.tab_item_text_7'),
    },
    {
      code: t('block_10.tab_1.item.tab_item_title_8'),
      value: t('block_10.tab_1.item.tab_item_text_8'),
    },
  ];

  return (
    <Box className={classes.TableW}>
      <Table>
        <TableBody>
          {rows.map((m, i) => (
            <TableRow key={i}>
              <TableCell className={classes.th}>
                <div dangerouslySetInnerHTML={{ __html: m.code }} />
              </TableCell>
              <TableCell className={classes.td}>
                <div dangerouslySetInnerHTML={{ __html: m.value }} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

const Faq = () => {
  const classes = useStylesTarifs({});
  const {t} = useTranslation()

  const rows = [
    {
      code: t('block_10.tab_3.item.tab_item_title_1'),
      value: t('block_10.tab_3.item.tab_item_text_1'),
    },
    {
      code: t('block_10.tab_3.item.tab_item_title_2'),
      value: t('block_10.tab_3.item.tab_item_text_2'),
    },
    {
      code: t('block_10.tab_3.item.tab_item_title_3'),
      value: t('block_10.tab_3.item.tab_item_text_3'),
    },
    {
      code: t('block_10.tab_3.item.tab_item_title_4'),
      value: t('block_10.tab_3.item.tab_item_text_4'),
    },
    {
      code: t('block_10.tab_3.item.tab_item_title_5'),
      value: t('block_10.tab_3.item.tab_item_text_5'),
    },
    {
      code: t('block_10.tab_3.item.tab_item_title_6'),
      value: t('block_10.tab_3.item.tab_item_text_6'),
    },
    {
      code: t('block_10.tab_3.item.tab_item_title_7'),
      value: t('block_10.tab_3.item.tab_item_text_7'),
    },
    {
      code: t('block_10.tab_3.item.tab_item_title_8'),
      value: t('block_10.tab_3.item.tab_item_text_8'),
    },
    {
      code: t('block_10.tab_3.item.tab_item_title_9'),
      value: t('block_10.tab_3.item.tab_item_text_9'),
    },
    {
      code: t('block_10.tab_3.item.tab_item_title_10'),
      value: t('block_10.tab_3.item.tab_item_text_10'),
    },
    {
      code: t('block_10.tab_3.item.tab_item_title_11'),
      value: t('block_10.tab_3.item.tab_item_text_11'),
    },
    {
      code: t('block_10.tab_3.item.tab_item_title_12'),
      value: t('block_10.tab_3.item.tab_item_text_12'),
    },
    {
      code: t('block_10.tab_3.item.tab_item_title_13'),
      value: t('block_10.tab_3.item.tab_item_text_13'),
    },
    {
      code: t('block_10.tab_3.item.tab_item_title_14'),
      value: t('block_10.tab_3.item.tab_item_text_14'),
    },
    {
      code: t('block_10.tab_3.item.tab_item_title_15'),
      value: t('block_10.tab_3.item.tab_item_text_15'),
    },
    {
      code: t('block_10.tab_3.item.tab_item_title_16'),
      value: t('block_10.tab_3.item.tab_item_text_16'),
    },
    {
      code: t('block_10.tab_3.item.tab_item_title_17'),
      value: t('block_10.tab_3.item.tab_item_text_17'),
    },
    {
      code: t('block_10.tab_3.item.tab_item_title_18'),
      value: t('block_10.tab_3.item.tab_item_text_18'),
    },
    {
      code: t('block_10.tab_3.item.tab_item_title_19'),
      value: t('block_10.tab_3.item.tab_item_text_19'),
    },
  ];

  return (
    <Box className={classes.TableWW}>
      {rows.map((m, i) => (
        <BccCollapsePanel>
          <BccCollapseTitle expandIcon={<ExpandMoreIcon />}>
            <BccTypography type="p2">
              <div dangerouslySetInnerHTML={{ __html: m.code }} />
            </BccTypography>
          </BccCollapseTitle>
          <BccCollapseDetails>
            <BccTypography type="p2">
              <div dangerouslySetInnerHTML={{ __html: m.value }} />
            </BccTypography>
          </BccCollapseDetails>
        </BccCollapsePanel>
      ))}
    </Box>
  );
};

const Tarifs = () => {
  const classes = useStylesTarifs({});
  const {t} = useTranslation()
  const rows = [
    {
      code: t('block_10.tab_2.item.tab_item_title_1'),
      value: t('block_10.tab_2.item.tab_item_text_1'),
    },
    {
      code: t('block_10.tab_2.item.tab_item_title_2'),
      value: t('block_10.tab_2.item.tab_item_text_2'),
    },
    {
      code: t('block_10.tab_2.item.tab_item_title_3'),
      value: t('block_10.tab_2.item.tab_item_text_3'),
    },
    {
      code: t('block_10.tab_2.item.tab_item_title_4'),
      value: t('block_10.tab_2.item.tab_item_text_4'),
    },
    {
      code: t('block_10.tab_2.item.tab_item_title_5'),
      value: t('block_10.tab_2.item.tab_item_text_5'),
    },
    {
      code: t('block_10.tab_2.item.tab_item_title_6'),
      value: t('block_10.tab_2.item.tab_item_text_6'),
    },
    {
      code: t('block_10.tab_2.item.tab_item_title_7'),
      value: t('block_10.tab_2.item.tab_item_text_7'),
    },
    {
      code: t('block_10.tab_2.item.tab_item_title_8'),
      value: t('block_10.tab_2.item.tab_item_text_8'),
    },
    {
      code: t('block_10.tab_2.item.tab_item_title_9'),
      value: t('block_10.tab_2.item.tab_item_text_9'),
    },
  ];

  return (
    <Box className={classes.TableW}>
      <Table>
        <TableBody>
          {rows.map((m, i) => (
            <TableRow key={i}>
              <TableCell className={classes.th}>{m.code}</TableCell>
              <TableCell className={classes.td}>{m.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

const Development = () => {
  const classes = useStylesTarifs({});
  const {t} = useTranslation()
  return (
      <Box className={classes.TableW}>
        <Table>
          <TableBody>
                <TableRow>
                  <TableCell className={classes.td}>{t('block_10.tab_4.item.tab_item_text_4')}</TableCell>
                </TableRow>
          </TableBody>
        </Table>
      </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    cashbackRowMain: {
      padding: '32px 0',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-evenly',
    },
    cashbackRow: {
      padding: '32px 40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      flexWrap: 'wrap',
      '& img': {
        filter: 'grayscale(1) contrast(10)'
      },
      '& a': {
        width: '100%',
        marginBottom: 16,
        background: '#FAFAFA',
        borderRadius: 8,
        padding: '14px 0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 'normal',
        fontSize: 24,
        color: '#000D1A',
        textDecoration: 'none'
      }
    },
    pdf:{
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: 14,
      color: "black",
      textDecoration: "none",
      display: "flex",
      alignItems: "center",
      margin: "4px 0",
      whiteSpace: "break-spaces"
    },

    [theme.breakpoints.down("sm")]: {
      root: {
        marginBottom: 40,
        position: "relative",
        maxWidth: "100%",
        margin: "0 auto",
        padding: "16px",
        color: "white",
      },
      title: {
        fontStyle: "normal",
        fontWeight: "bold",
        marginBottom: 20,
        fontSize: 20,
        color: "#141414",
      },
    },
    [theme.breakpoints.between("sm", "xl")]: {
      root: {
        maxWidth: 1280,
        padding: "32px",
        margin: "0 auto",
        boxSizing: "border-box",
        position: "relative",
      },
      title: {
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 40,
        marginBottom: 40,
        color: "#141414",
      },
    },
  })
);

const AdditionalInfo = () => {
  const classes = useStyles({});
  const {t, i18n} = useTranslation()
  const swipeTab = (index: number) => {
    const actionName =
        index === 0 ? "Iron_Additionally_Rates" : "Iron_Additionally_Conditions";

    ReactGA.event({
      category: `BccIronCard_${actionName}`,
      action: actionName,
    });
  };

  return (
      <Grid container className={classes.root}>
        <Grid className={classes.cashbackRowMain} item xl={12} lg={12} md={12} sm={12} xs={12}>
          <p style={{"width": "100%"}} className={classes.title}>{t('block_9.doc')}</p>
          <div className={classes.cashbackRow}>
            <a target='_blank' href={`/juniorbank/files/tariffs-${i18n.language}.pdf`} className={classes.pdf}>
              <img src="/juniorbank/images/pdf.svg" alt="pdf"/>
              &nbsp; {t('block_9.tarif')}
            </a>
          </div>
          <div className={classes.cashbackRow}>
            <a target='_blank' href={`/juniorbank/files/cashback-${i18n.language}.pdf`}
               className={classes.pdf}>
              <img src="/juniorbank/images/pdf.svg" alt="pdf"/>
              &nbsp; {t('block_9.cashback')}
            </a>
          </div>
          <div className={classes.cashbackRow}>
            <a target='_blank' href={`/juniorbank/files/prohibited-transactions-${i18n.language}.xlsx`}
               className={classes.pdf}>
              <img src="/juniorbank/images/pdf.svg" alt="pdf"/>
              &nbsp; {t('block_9.prohibited_transaction')}
            </a>
          </div>
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <p className={classes.title}>{t('block_10.title')}</p>
          <Tab
              onHandleChanged={(i: number) => swipeTab(i)}
              menuTitle={[
                t('block_10.tab_1.tab_title'),
                t('block_10.tab_2.tab_title'),
                t('block_10.tab_3.tab_title'),
                <>
                  <img src={process.env.PUBLIC_URL + "/new.svg"}/> {t('block_10.tab_4.tab_title')}
                </>,
              ]}
          pans={[<Benefits />, <Tarifs />, <Faq />, <Development />]}
        />
      </Grid>
    </Grid>
  );
};

export default AdditionalInfo;
