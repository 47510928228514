import React from "react";
import {Grid, IconButton} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {BccTypography} from "./BccComponents";
import CloseIcon from "@material-ui/icons/Close";
import {useTranslation} from "react-i18next";

export const rootStyles = makeStyles((theme: Theme) =>
    createStyles({
      googleForm: {
        'border': 'none',
        'boxShadow': 'none',
        'margin': '0 auto',
        'width': '100%'
      },

      '@keyframes snowflakesFall': {
        "0%": {
        top: '-10%'
      },
      '100%': {
        top: '100%'
      }
    },
    [theme.breakpoints.down("sm")]: {

      bannerCatImg: {
        display: 'none',
      },
      root: {
        maxWidth: "100%",
        margin: "0 auto",
        padding: "0 16px 32px",
        marginTop: 40,
      },
      title: {
        marginBottom: 30,
        fontSize: 32,
        fontWeight: "bold",
        lineHeight: 1,
      },
      list: {
        alignItems: "center",
        "& > div": {
          width: "100%",
          padding: "16px",
          boxSizing: "border-box",
          backgroundColor: "#F1F7F9",
          borderRadius: 8,
          marginBottom: 30,
          "& > div": {
            alignItems: "center",
            flexWrap: "wrap",
            justifyContent: "flex-start",
            "& > div:first-child": {
              marginRight: 20,
            },
            "& > div:last-child": {
              width: "calc(100% - 90px)",
            },
          },
        },
      },
    },
    [theme.breakpoints.between("md", "xl")]: {
      root: {
        maxWidth: 1280,
        margin: "0 auto",
        boxSizing: "border-box",
        padding: "0 56px 120px",
      },
      title: {
        marginBottom: 30,
      },
      list: {
        alignItems: "center",
        "& > div": {
          width: "calc(50% - 15px)",
          padding: "36px 72px",
          boxSizing: "border-box",
          backgroundColor: "#F1F7F9",
          borderRadius: 8,
          marginBottom: 30,
          "& > div": {
            alignItems: "center",
            flexWrap: "nowrap",
            justifyContent: "flex-start",
            "& > div:first-child": {
              marginRight: 50,
            },
          },
        },
      },

      fixedBtnModal: {
        zIndex: 1003,
        top: 16,
        left: 56,
        width: 32,
        height: 32,
        display: 'flex',
        background: "#2D72E5",
        position: "relative",
        marginLeft: 'auto',
        borderRadius: '50%',
        cursor: 'pointer',
        transition: '.3s',
        "&:hover": {
          opacity: 0.6
        },
        "&:before": {
          content: "' '",
          position: "absolute",
          margin: "auto",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          width: 2,
          height: 16,
          background: "white",
          borderRadius: '20px',
          transform: 'rotate(-45deg)'
        },
        "&:after": {
          content: "' '",
          position: "absolute",
          margin: "auto",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          width: 2,
          height: 16,
          background: "white",
          borderRadius: '20px',
          transform: 'rotate(45deg)'
        }
      },
      modalMain: {
        zIndex: 1000,
        background: "white",
        height: '80vh',
        width: 920,
        position: "relative",
        padding: "16px",
        borderRadius: 20,
      },
      fixedModal: {
        display: "none",
        position: "fixed",
        margin: "auto",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        opacity: 1,
        transition: ".3s",
        "& img": {
          zIndex: 102,
          width: "100%",
        },
      },
      fixedImgModal: {
        zIndex: 103,
        opacity: 1,
        "& img:last-child": {
          display: "none",
        },
      },
      modalTitle: {
        overflowY: "auto",
        overflowX: "hidden",
        height: 'calc(100% - 32px)',
        "& h3": {
          marginTop: 0,
          fontSize: 24,
          color: "#141414",
        },
        "& p": {
          margin: "8px 0 16px 0",
        },
        "& h4": {
          fontSize: 14,
          margin: "0",
        },
      },
      gridTableCols: {

      },
      trTable: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px 0',
        borderBottom: '1px solid #F3F3F3'
      },
      trNewTr: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
      },
      col6: {
        width: '49%',
        textAlign: 'right',
        fontWeight: 'normal',
        fontSize: 14,
        color: '#80868C'
      },
      col6r: {
        paddingLeft: 16,
        width: '49%',
        fontWeight: 'normal',
        fontSize: 14,
        color: '#000000'
      },
      trHead: {
        position: 'relative',
        '& img': {
          position: 'absolute',
          width: 72,
          right: 30,
          bottom: -30
        },
        '& h3': {
          textAlign: 'center',
          fontWeight: 'bold',
          fontSize: 18,
          color: '#000000',
        },
        '& p': {
          textAlign: 'center',
          fontWeight: '500',
          fontSize: 16,
          color: '#000000',
          margin: 0
        },
      },
      mobCatImg: {
        display: 'none'
      },
      trFooter: {
        '& h5': {
          fontSize: 16,
          margin: '32px 0 8px 0'
        },
        '& span': {
          fontSize: 14,
          display: 'block',
          margin: '0 0 8px 0'
        },
        '& p': {
          fontWeight: '500',
          fontSize: 16,
          color: '#000000',
          margin: '0 0 8px 0'
        }
      },
      trBody: {
        '& h4': {
          fontSize: 18,
          margin: '8px 0 8px 0'
        },
        '& p': {
          fontWeight: '500',
          fontSize: 14,
          color: '#000000',
          margin: '0 0 8px 0'
        }
      },
      textHidden: {
        display: 'none',
      },
      textShow: {
        display: 'block',
      },
      listAll: {
        textDecoration: 'underline',
        margin: '0 0 12px 0',
        display: 'block',
        cursor: 'pointer'
      },
      viewAll: {
        textDecoration: 'underline',
        margin: '0 0 12px 0',
        display: 'block',
        cursor: 'pointer'
      },
      bannerCat: {
        position: "relative",
        height: '100%',
        margin: '32px 0',
        overflow: 'hidden',
        zIndex: 9,
        cursor: 'pointer'
      },
      bannerCatImg: {
        width: '100%',
        borderRadius: 16
      },
      bannerMob: {
        display: 'none'
      },
      catImg: {
        position: "absolute",
        bottom: 4,
        width: 'auto',
        animation: "$catMode 5s linear infinite",
      },
      snowFlake: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        margin: 'auto',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        '& span': {
          color: '#fff',
          fontSize: '1em',
          position: 'absolute',
          top: '-10%',
          zIndex: '9999',
          userSelect: 'none',
          cursor: 'default',
          animationName: '$snowflakesFall, $snowflakesShake',
          animationDuration: '10s, 3s',
          animationTimingFunction: 'linear, ease-in-out',
          animationIterationCount: 'infinite, infinite',
          animationPlayState: 'running, running',
          '&:nth-child(1)': {
            left: '1%',
            animationDelay: '0s, 0s'
          },
          '&:nth-child(2)': {
            left: '10%',
            animationDelay: '1s, 1s'
          },
          '&:nth-child(3)': {
            left: '20%',
            animationDelay: '6s, 0.5s'
          },
          '&:nth-child(4)': {
            left: '30%',
            animationDelay: '4s, 2s'
          },
          '&:nth-child(5)': {
            left: '40%',
            animationDelay: '2s, 2s'
          },
          '&:nth-child(6)': {
            left: '50%',
            animationDelay: '8s, 3s'
          },
          '&:nth-child(7)': {
            left: '60%',
            animationDelay: '6s, 2s'
          },
          '&:nth-child(8)': {
            left: '70%',
            animationDelay: '2.5s, 1s'
          },
          '&:nth-child(9)': {
            left: '80%',
            animationDelay: '1s, 0s'
          },
          '&:nth-child(10)': {
            left: '90%',
            animationDelay: '3s, 1.5s'
          }
        },
      }
    },
    [theme.breakpoints.down("xs")]: {
      root: {
        marginTop: 0,
      },
      title: {
        textAlign: "center",
      },

      fixedBtnModal: {
        zIndex: 1003,
        width: 32,
        height: 32,
        display: 'flex',
        background: "#2D72E5",
        position: "relative",
        marginLeft: 'auto',
        borderRadius: '50%',
        cursor: 'pointer',
        transition: '.3s',
        "&:hover": {
          opacity: 0.6
        },
        "&:before": {
          content: "' '",
          position: "absolute",
          margin: "auto",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          width: 2,
          height: 16,
          background: "white",
          borderRadius: '20px',
          transform: 'rotate(-45deg)'
        },
        "&:after": {
          content: "' '",
          position: "absolute",
          margin: "auto",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          width: 2,
          height: 16,
          background: "white",
          borderRadius: '20px',
          transform: 'rotate(45deg)'
        }
      },
      modalMain: {
        zIndex: 1000,
        background: "white",
        height: '80vh',
        width: 'calc(100% - 50px)',
        position: "relative",
        padding: "16px",
        borderRadius: 20,
        margin: "0 auto"
      },
      fixedModal: {
        display: "none",
        position: "fixed",
        margin: "auto",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        opacity: 1,
        transition: ".3s",
        "& img": {
          zIndex: 102,
          width: "100%",
        },
      },
      fixedImgModal: {
        zIndex: 103,
        opacity: 1,
        "& img:last-child": {
          display: "none",
        },
      },
      modalTitle: {
        overflowY: "auto",
        overflowX: "hidden",
        height: 'calc(100% - 32px)',
        "& h3": {
          marginTop: 0,
          fontSize: 24,
          color: "#141414",
        },
        "& p": {
          margin: "8px 0 16px 0",
        },
        "& h4": {
          fontSize: 14,
          margin: "0",
        },
      },
      gridTableCols: {

      },
      trBody: {
        '& h4': {
          fontSize: 18,
          margin: '8px 0 8px 0'
        },
        '& p': {
          fontWeight: '500',
          fontSize: 14,
          color: '#000000',
          margin: '0 0 8px 0'
        }
      },
      textHidden: {
        display: 'none',
      },
      textShow: {
        display: 'block',
      },
      listAll: {
        textDecoration: 'underline',
        margin: '16px 0 12px 0',
        display: 'block',
        cursor: 'pointer'
      },
      viewAll: {
        textDecoration: 'underline',
        margin: '16px 0 12px 0',
        display: 'block',
        cursor: 'pointer'
      },
      trFooter: {
        '& h5': {
          fontSize: 16,
          margin: '32px 0 8px 0'
        },
        '& span': {
          fontSize: 14,
          display: 'block',
          margin: '0 0 8px 0'
        },
        '& p': {
          fontWeight: '500',
          fontSize: 16,
          color: '#000000',
          margin: '0 0 8px 0'
        }
      },
      trTable: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        padding: '10px 0',
        borderBottom: '1px solid #F3F3F3'
      },
      col3: {
        width: '100%',
        textAlign: 'left',
        fontWeight: 'normal',
        fontSize: 14,
        marginBottom: 8,
        color: '#80868C'
      },
      col9: {
        paddingLeft: 0,
        width: '100%',
        fontWeight: 'normal',
        fontSize: 14,
        color: '#000000'
      },
      trHead: {
        position: 'relative',
        '& img': {
          position: 'absolute',
          width: 72,
          right: 30,
          bottom: -70
        },
        '& h3': {
          textAlign: 'center',
          fontWeight: 'bold',
          fontSize: 18,
          color: '#000000',
        },
        '& p': {
          textAlign: 'center',
          fontWeight: '500',
          fontSize: 16,
          color: '#000000',
          margin: 0
        },
      },


      bannerCat: {
        position: "relative",
        height: '100%',
        margin: '32px 0',
        overflow: 'hidden',
        zIndex: 9,
        cursor: 'pointer'
      },
      bannerCatImg: {
        display: 'none',
        width: '100%'
      },
      bannerMob: {
        width: '100%',
        display: 'block',
        borderRadius: 16
      },
      catImg: {
        position: "absolute",
        top: 4,
        width: '200px',
        animation: "$catMode 5s linear infinite",
      },
      snowFlake: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        margin: 'auto',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        '& span': {
          color: '#fff',
          fontSize: '1em',
          position: 'absolute',
          top: '-10%',
          zIndex: '9999',
          userSelect: 'none',
          cursor: 'default',
          animationName: '$snowflakesFall, $snowflakesShake',
          animationDuration: '10s, 3s',
          animationTimingFunction: 'linear, ease-in-out',
          animationIterationCount: 'infinite, infinite',
          animationPlayState: 'running, running',
          '&:nth-child(1)': {
            left: '1%',
            animationDelay: '0s, 0s'
          },
          '&:nth-child(2)': {
            left: '10%',
            animationDelay: '1s, 1s'
          },
          '&:nth-child(3)': {
            left: '20%',
            animationDelay: '6s, 0.5s'
          },
          '&:nth-child(4)': {
            left: '30%',
            animationDelay: '4s, 2s'
          },
          '&:nth-child(5)': {
            left: '40%',
            animationDelay: '2s, 2s'
          },
          '&:nth-child(6)': {
            left: '50%',
            animationDelay: '8s, 3s'
          },
          '&:nth-child(7)': {
            left: '60%',
            animationDelay: '6s, 2s'
          },
          '&:nth-child(8)': {
            left: '70%',
            animationDelay: '2.5s, 1s'
          },
          '&:nth-child(9)': {
            left: '80%',
            animationDelay: '1s, 0s'
          },
          '&:nth-child(10)': {
            left: '90%',
            animationDelay: '3s, 1.5s'
          }
        },
      }
    },
    root: {
      position: "relative",
    },
    open: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 100,
      transition: ".3s",
    },
    drpBack: {
      width: "100%",
      height: "100%",
      background: "#00000099",
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 100,
      cursor: "pointer",
    },
    closed: {
      display: "none",
    },
  })
);

const Safety = () => {
  const classes = rootStyles({});
  const [openHappy, setOpenHappy] = React.useState(false);
  const {t, i18n} = useTranslation()

  return (
      <div className={classes.root} id="secondHeader">


        <Grid className={classes.bannerCat}>
          <a href={'https://www.bcc.kz/juniorbank/14iphone/promotion'}>
            <img className={classes.bannerCatImg}
                 src={process.env.PUBLIC_URL + `/images/promotion-web-${i18n.language}.png`}/>
            <img className={classes.bannerMob}
                 src={process.env.PUBLIC_URL + `/images/promotion-mob-${i18n.language}.png`}/>
          </a>
        </Grid>

        <Grid className={classes.bannerCat}>
          <a href={'https://www.bcc.kz/juniorbank/financial-lessons/?utm_source=banner&utm_medium=junior_title'}>
            <img className={classes.bannerCatImg}
                 src={process.env.PUBLIC_URL + `/images/financial-lessons-web-${i18n.language}.png`}/>
            <img className={classes.bannerMob}
                 src={process.env.PUBLIC_URL + `/images/financial-lessons-mob-${i18n.language}.png`}/>
          </a>
        </Grid>

        <Grid className={classes.bannerCat}>
          <a href={'https://www.bcc.kz/juniorbank/parents-task'}>
            <img className={classes.bannerCatImg}
                 src={process.env.PUBLIC_URL + `/images/partners-task-banner-web-${i18n.language}.png`}/>
            <img className={classes.bannerMob}
                 src={process.env.PUBLIC_URL + `/images/partners-task-banner-mob-${i18n.language}.png`}/>
          </a>
        </Grid>


        <Grid className={classes.bannerCat}>
          <a href={'https://www.bcc.kz/juniorbank/new-features'}>
            <img className={classes.bannerCatImg}
                 src={process.env.PUBLIC_URL + `/images/new-features-banner-${i18n.language}.png`}/>
            <img className={classes.bannerMob}
                 src={process.env.PUBLIC_URL + `/images/new-features-banner-mob-${i18n.language}.png`}/>
          </a>
        </Grid>

        <Grid className={classes.bannerCat}>
          <a href={'https://www.bcc.kz/juniorbank/deposit-info'}>
            <img className={classes.bannerCatImg}
                 src={process.env.PUBLIC_URL + `/images/junior-deposit-banner-${i18n.language}.jpg`}/>
            <img className={classes.bannerMob}
                 src={process.env.PUBLIC_URL + `/images/junior-deposit-banner-mob-${i18n.language}.jpg`}/>
          </a>
        </Grid>

        <Grid className={`${classes.fixedModal} ${openHappy ? classes.open : ""}`}>
          <Grid style={{}} className={classes.modalClose}>
            <IconButton
                className={classes.fixedBtnModal}
                onClick={() => setOpenHappy(false)}
            >
              <CloseIcon/>
            </IconButton>
          <Grid style={{padding: "20px", "height": "50vh"}} className={`${classes.modalMain}  `}>
          </Grid>
        </Grid>
          <div onClick={() => setOpenHappy(false)}></div>
      </Grid>

      <BccTypography type="h1" block className={classes.title}>
        {t('block_1.title')}
      </BccTypography>

      <Grid
        container
        justify="space-between"
        className={classes.list}
        wrap="wrap"
      >
        <Grid item>
          <Grid container justify="space-between">
            <Grid item>
              <img src={process.env.PUBLIC_URL + "/safety1.svg"} />
            </Grid>
            <Grid item>
              <BccTypography type="p2l" block>
                {t('block_1.option_1')}
              </BccTypography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container justify="space-between">
            <Grid item>
              <img src={process.env.PUBLIC_URL + "/safety2.svg"} />
            </Grid>
            <Grid item>
              <BccTypography type="p2l" block>
                {t('block_1.option_2')}
              </BccTypography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container justify="space-between">
            <Grid item>
              <img src={process.env.PUBLIC_URL + "/safety3.svg"} />
            </Grid>
            <Grid item>
              <BccTypography type="p2l" block>
                {t('block_1.option_3')}
              </BccTypography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container justify="space-between">
            <Grid item>
              <img src={process.env.PUBLIC_URL + "/safety4.svg"} />
            </Grid>
            <Grid item>
              <BccTypography type="p2l" block>
                {t('block_1.option_4')}
              </BccTypography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

    </div>
  );
};

export default Safety;
