import React from "react";
import {useTranslation} from "react-i18next";

export const Benefits = () => {
    const {t} = useTranslation()

    return (
        <div className={'benefits-container'}>
            <div className={'benefits'}>
                <div className={'benefit-container'}
                     dangerouslySetInnerHTML={{__html: t('iphone-promotion-page.benefit-1')}}/>

                <div className={'benefit-container'}
                     dangerouslySetInnerHTML={{__html: t('iphone-promotion-page.benefit-2')}}/>

                {/*<div className={'benefit-container'} dangerouslySetInnerHTML={{__html: t('parents-task.benefit-3')}}/>*/}

                {/*<div className={'benefit-container'} dangerouslySetInnerHTML={{__html: t('parents-task.benefit-4')}}/>*/}

            </div>
        </div>
    )
}
