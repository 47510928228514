import React from "react";
import * as Scroll from "react-scroll";
import {ParallaxProvider} from "react-scroll-parallax";
import {
    AdditionalInfo,
    CardOrder,
    FixedHeader, Footer,
    Header, HelpYou, HowToGet,
    MobileApp,
    PhoneCase1,
    PhoneCase2,
    Reason,
    Safety
} from "../../components";
import SnackBarBottom from "../../components/SnackBar";
import { ScrollWrapper } from "../../components/ScrollWrapper";

export const MainPage = () => {
    const orderRef: any = React.useRef(null);
    const snackUp = (message: string) => {
        setMessage(message);
        setSend(true);
    };
    const scrollToOrderRef = (flag: boolean) => {
        Scroll.animateScroll.scrollTo(
            flag ? orderRef.current.offsetTop - 120 : orderRef.current.offsetTop - 80
        );
    };

    const [isSend, setSend] = React.useState<boolean>(false);
    const [message, setMessage] = React.useState<string>("default");
    return (
        <>
            <ScrollWrapper>
            <ParallaxProvider>
                <Header scrollToOrder={scrollToOrderRef}/>
                <FixedHeader scrollToOrder={scrollToOrderRef}/>
                <Safety/>
                <PhoneCase1/>
                <MobileApp/>
                <PhoneCase2/>
                <Reason/>

                <CardOrder
                    refProp={orderRef}
                    snackUp={(message: string) => snackUp(message)}
                    scrollToOrder={scrollToOrderRef}
                />
                <HowToGet/>
                <AdditionalInfo/>
                <HelpYou/>
                <Footer/>
            </ParallaxProvider>
            </ScrollWrapper>

            <SnackBarBottom
                open={isSend}
                message={message}
                close={() => setSend(false)}
            />
        </>

    )
}


