import React from "react";
import {useTranslation} from "react-i18next";

export const FirstBanner = () => {

    const {t} = useTranslation()

    return (
        <div className={'first-banner-container'}>
            <div className={'first-banner-title'}
                 dangerouslySetInnerHTML={{__html: t('iphone-promotion-page.first-banner')}}/>
            <img src={process.env.PUBLIC_URL + '/iphone-promotion/iphone.png'}/>
        </div>
    )
}
