import React from "react";
import {useTranslation} from "react-i18next";

export const FirstFinLessonsBanner = () => {

    const {t, i18n} = useTranslation()

    return (
        <div className={'first-banner-container'}>
            <div className={'first-banner-title'}
                 dangerouslySetInnerHTML={{__html: t('financial-lessons.first-banner')}}/>
            <img src={process.env.PUBLIC_URL + `/financial-lessons/photo-${i18n.language}.png`}/>
        </div>
    )
}
