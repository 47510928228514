import React, {useEffect, useState} from "react";
import {Grid} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import ReactGA from "react-ga";
import {BccButton, BccTypography} from "./BccComponents";
import {Parallax} from "react-scroll-parallax";
import {useTranslation} from "react-i18next";
import {LangSelect} from "./LangSelect";
import TagManager from "react-gtm-module";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      "@keyframes fullRotation": {
        "0%": {
          transform:
              "translate3d(0px, 0px, 0px) rotate3d(0, 1, 0, 0deg) rotate(-10deg)",
        },
        "20%": {
          transform:
          "translate3d(0px, -5px, 0px) rotate3d(0, 1, 0, 60deg) rotate(-10deg)",
      },
      "50%": {
        transform:
          "translate3d(0px, 0px, 0px) rotate3d(0, 1, 0, 0deg) rotate(-10deg)",
      },
      "90%": {
        transform:
          "translate3d(0px, -5px, 0px) rotate3d(0, 1, 0, -360deg) rotate(-10deg)",
      },
      "100%": {
        transform:
          "translate3d(0px, 0px, 0px) rotate3d(0, 1, 0, -360deg) rotate(-10deg)",
      },
    },

        "@keyframes fullRotation2": {
            "0%": {
                transform: "translate3d(0px, -5px, 0px) rotate3d(0, 1, 0, -90deg) rotate(-10deg)"
            },
            "30%": {
                transform: "translate3d(0px, -5px, 0px) rotate3d(0, 1, 0, -30deg) rotate(-10deg)"
            },
            "60%": {
                transform: " translate3d(0px, 0px, 0px) rotate3d(0, 1, 0, -90deg) rotate(-10deg)"
            },
            "90%": {
                transform: "translate3d(0px, -5px, 0px) rotate3d(0, 1, 0, -450deg) rotate(-10deg)"
            },
            "100%": {
                transform: "translate3d(0px, 0px, 0px) rotate3d(0, 1, 0, -450deg) rotate(-10deg)"
            }
        },

        "@keyframes appear": {
            "0%": {
                opacity: 1
            },
            "20%": {
                opacity: 1
            },
            "50%": {
                opacity: 1
            },
            "75%": {
                opacity: 0
            },
            "100%": {
                opacity: 1
            },
        },
    "@keyframes cardMode": {
      from: {
        transform: "translateY(0)",
      },
      to: {
        transform: "translateY(200px)",
      },
    },
    "@keyframes cardMode2": {
      from: {
        transform: "translateY(0) scale(0) rotate(0deg)",
        opacity: 1,
        filter: "blur(0px)",
      },
      to: {
        transform: "translateY(20px) scale(1) rotate(360deg)",
        opacity: 0,
        filter: "blur(20px)",
      },
    },
    "@keyframes cardMode3": {
      from: {
        transform: "translateY(0)",
      },
      to: {
        transform: "translateY(20px)",
      },
    },
    [theme.breakpoints.between("md", "xl")]: {
      headerRoot: {
        width: "100%",
      },
      headerBgPart: {
        position: "absolute",
        "& > img": {
          width: "100%",
        },
      },
      headerBgPart1: {
        left: "-90px",
        top: 355,
        "& > figure > div > img": {},
      },
      headerBgPart2: {
        left: "35%",
        top: 36,
        "& > figure > div > img": {
          animation: "$cardMode2 8s linear infinite",
        },
      },
      headerBgPart3: {
        right: "-30%",
        top: 0,
        "& > figure > div > img": {
          animation: "$cardMode3 5s alternate infinite",
        },
      },
      headerBgPart4: {
        right: 91,
        top: 430,
        "& > figure > div > img": {
          animation: "$cardMode2 6s linear infinite",
        },
      },
      headerBtn: {
        backgroundColor: "#2D72E5!important",
        fontSize: 18,
        fontWeight: "bold",
        minWidth: 325,
      },
      header: {
        maxWidth: 1280,
        width: "100%",
        boxSizing: "border-box",
        margin: "0 auto",
        padding: "56px",
        zIndex: 99,
        position: "relative",
      },
      headerTitle: {
        marginBottom: 20,
        fontWeight: "bold",
      },
      headerSubTitle: {
        marginBottom: 48,
        lineHeght: "140%",
      },
      headerContent: {
        marginTop: 96,
      },
      juniorcard: {
        "& > img": {
          width: "100%",
          display: "block",
          maxWidth: 450,
          transform:
              "translate3d(0px, -5px, 0px) rotate3d(0, 1, 0, -450deg)  rotate(-10deg)",
          transition: "350ms all",
            animation: "4.5s $fullRotation ease 1s",
        },
      },
    },

    [theme.breakpoints.down("sm")]: {
      headerRoot: {
        width: "100%",
      },
      brSm: {
        display: "none",
      },
      headerBgPart: {
        position: "absolute",
        "& > img": {
          width: "100%",
        },
      },
      headerBgPart1: {
        left: 0,
        top: 355,
        animation: "$cardMode 2s alternate infinite",
        display: 'none'
      },
      headerBgPart2: {
        left: "65%",
        top: 36,
        animation: "$cardMode2 8s linear infinite",
        display: 'none'
      },
      headerBgPart3: {
        display: "none",
      },
      headerBgPart4: {
        right: 91,
        top: 430,
        display: 'none',
        animation: "$cardMode2 6s linear infinite",
      },
      headerBtn: {
        backgroundColor: "#2D72E5!important",
        fontSize: 18,
        fontWeight: "bold",
        minWidth: "100%",
      },
      header: {
        maxWidth: "100%",
        width: "100%",
        margin: "0 auto",
        padding: "32px 16px",
        zIndex: 99,
        position: "relative",
      },
      headerTitle: {
        marginBottom: 20,
        fontWeight: "bold",
        fontSize: 32,
        lineHeight: 1,
      },
      headerSubTitle: {
        marginBottom: 48,
        fontWeight: "normal",
        lineHeght: "140%",
        fontSize: 18,
      },
      headerContent: {
        marginTop: 64,
      },
      juniorcard: {
        "& > img": {
          width: "90%",
          margin: "0 auto",
          display: "block",
          maxWidth: 450,
            animation: "4.5s $appear ease infinite 1s",
        },
      },
    },
    [theme.breakpoints.down("xs")]: {
      headerContent: {
        marginTop: 32,
        textAlign: "center",
        "& > div": {},
      },
      headerSubTitle: {
        padding: "0 12px",
        "& > br": { display: "none" },
      },
      juniorcard: {
        "& > img": {
          width: "100%",
          margin: "32px auto",
          display: "block",
          maxWidth: "initial",
        },
      },
    },
  })
);


const Header = (props: any) => {
  const classes = useStyles({});
  const {t} = useTranslation()

    const [currentCard, setCurrentCard] = useState(0)

    const {startTimer, remainingSecondsCount} = useTimer(4.5)


    useEffect(() => {
        const secondsCount = +remainingSecondsCount.toFixed(1)
        if (secondsCount === 0) {
            startTimer()
        }
    }, [remainingSecondsCount]);

    useEffect(() => {
        startTimer()
    }, []);

    useEffect(() => {
        const cardObject = document.getElementsByClassName(classes.juniorcard)

        cardObject.item(0)?.addEventListener('animationend', function (ev) {
            setCurrentCard(currentCard < 3 ? currentCard + 1 : 0)
        })

        return () => {
            cardObject.item(0)?.removeEventListener('animationend', function (ev) {
            })
        }
    }, [currentCard]);

  const order = (e: any) => {
    e.preventDefault();
    ReactGA.event({
      category: "CTA_order_juniorcrad",
      action: "order_juniorcard",
    });
    TagManager.dataLayer({
      dataLayer: {
        event: "junior_1button_to_order_card",
      },
    });
    props.scrollToOrder();
  };


  return (
    <div className={classes.headerRoot}>
      <div className={`${classes.headerBgPart} ${classes.headerBgPart1}`}>
        <Parallax translateY={[120, 0]}>
          <img
            src={process.env.PUBLIC_URL + "/headerBgPart1.svg"}
            alt="bgpart1"
          />
        </Parallax>
      </div>
      <div className={`${classes.headerBgPart} ${classes.headerBgPart2}`}>
        <Parallax translateY={[120, 0]}>
          <img
            src={process.env.PUBLIC_URL + "/headerBgPart2.svg"}
            alt="bgpart2"
          />
        </Parallax>
      </div>
      <div className={`${classes.headerBgPart} ${classes.headerBgPart3}`}>
        <Parallax translateY={[120, 0]} >
          <img
            src={process.env.PUBLIC_URL + "/headerBgPart3.svg"}
            alt="bgpart3"
          />
        </Parallax>
      </div>
      <div className={`${classes.headerBgPart} ${classes.headerBgPart4}`}>
        <Parallax translateY={[120, 0]} >
          <img
            src={process.env.PUBLIC_URL + "/headerBgPart4.svg"}
            alt="bgpart4"
          />
        </Parallax>
      </div>

      <Grid container direction="column" className={classes.header}>
        <Grid container justify={'space-between'} alignItems={'center'} item>
          <a href="https://bcc.kz">
            <img src={process.env.PUBLIC_URL + "/logo-new.svg"} alt="BCC Logo"/>
          </a>
          <LangSelect/>
        </Grid>
        <Grid item className={classes.headerContent}>
          <Grid container justify="space-between">
            <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                className={classes.headerContentText}
            >
              <BccTypography type="h1" block className={classes.headerTitle}>
                {t('header.title')}
              </BccTypography>
              <BccTypography type="p2" block className={classes.headerSubTitle}>
                {t('header.description')}
              </BccTypography>
              <BccButton
                  variant="contained"
                  className={classes.headerBtn}
                  color="primary"
                  onClick={(e: any) => order(e)}
                  size="large"
              >
                {t('header.button')}
              </BccButton>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              id={'animation grid'}
              className={classes.juniorcard}
              onAnimationEnd={() => console.log('en1d')}
              onTransitionEnd={() => console.log('en11d')}
              onAnimationEndCapture={() => console.log('123')}
              onTransitionEndCapture={() => console.log('1232')}
            >
              <img
                  src={process.env.PUBLIC_URL + `/cards/card-${currentCard}.svg`}
                alt="juniorcard"
                  onAnimationEnd={() => console.log('end')}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};


export const useTimer = (secondsCount: number) => {
    const [remainingSecondsCount, setRemainingSecondsCount] = useState(secondsCount);
    const [timer, setTimer] = useState<NodeJS.Timeout | undefined>(undefined);

    useEffect(() => {
        if (remainingSecondsCount === 0) {
            // @ts-ignore
            clearInterval(timer);
        }
    }, [remainingSecondsCount]);

    const startTimer = () => {
        if (timer) {
            clearInterval(timer);
        }

        setRemainingSecondsCount(secondsCount);
        const newTimer = setInterval(() => setRemainingSecondsCount((prevState) => prevState - 0.1), 100);
        setTimer(newTimer);
    };

    return {remainingSecondsCount, startTimer};
};

export default Header;
