import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import ReactGA from "react-ga";
import {Parallax} from "react-scroll-parallax";
import {Grid} from "@material-ui/core";
import React, {Dispatch, useEffect, useState} from "react";
import {CSSTransition, SwitchTransition} from "react-transition-group";
import './style.scss'
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import {BccTypography} from "../../../../components/BccComponents";
import {FadeTransition} from "../../../deposit-info/components/deposit";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        [theme.breakpoints.down("sm")]: {
            rootOutside: {
                fontFamily:"Montserrat",
                position: "relative",
                "& > img": {
                    display: "none",
                },
            },
            root: {
                position: "relative",
                maxWidth: "100%",
                margin: "0 auto",
                zIndex: 1,
                marginTop: 32,
                padding: "0 16px 32px",
                color: "white",
            },
            title: {
                marginBottom: 32,
                fontSize: 28,
                fontWeight: "bold",
                lineHeight: 1,
            },
            subTitle: {
                marginBottom: 32,
                fontWeight: 500,
            },
            lOut: {
                flexWrap: "nowrap",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
            },
            qr: {
                "& > img": {
                    height: 70,
                },
            },
            imgBlock: {
                gridArea: "a",
                width: "60%",
                position: "relative",
                zIndex: 4,
                textAlign: "right",
                "& > img:first-child": {
                    position: "relative",
                    zIndex: 3,
                    width: "100%",
                    maxHeight: 500,
                },
                "& > figure": {
                    display: "none",
                },
            },
            mobileLinks: {
                marginRight: 24,
                "& > a:last-child": {
                    marginBottom: 0,
                },
                "& > a": {
                    display: "block",
                    marginBottom: 12,
                    width: 120,
                    "& > img": {
                        display: "block",
                        width: 120,
                    },
                },
            },
            as: {
                marginBottom: 10,
            },
            textBlock: {width: "100%", padding: "32px 16px 0"},
            container: {
                position: "relative",
                zIndex: 3,
                backgroundColor: "#2D72E5",
                borderRadius: 24,
                width: "100%",
                flexWrap: "nowrap",
                display: "flex",
                alignItems: "center",
            },
            storesApp: {
                flexWrap: "nowrap",
                justifyContent: "flex-end",
            },
            appStore: {
                marginRight: 22,
                "& img": {
                    width: "100%",
                },
            },
            googleMarker: {
                "& img": {
                    width: "100%",
                },
            },
            headerBgPart: {
                position: "absolute",
                right: 0,
                bottom: 0,
                zIndex: 0,
                "& > figure": {display: "none", margin: 0},
                "& > img": {
                    width: "100%",
                },
            },
            headerBgPart1: {
                zIndex: 0,
                left: 0,
                top: 0,
            },
            headerBgPart2: {
                display: 'none',

                left: 50,
                zIndex: 2,
                bottom: -30,
            },
            headerBgPart3: {
                display: 'none',
                top: 0,
                right: 0,
                zIndex: 4,
            },
            headerBgPart4: {
                display: 'none',

                right: "30%",
                bottom: -30,
            },
            qrMob: {
                display: "none",
            },
        },
        [theme.breakpoints.between("md", "xl")]: {
            rootOutside: {
                fontFamily:"Montserrat",

                position: "relative",
            },
            qrMob: {
                display: "none",
            },
            headerBgPart: {
                position: "absolute",
                right: 0,
                bottom: 0,
                zIndex: 0,
                "&>figure": {margin: 0},
                "& > img": {
                    width: "100%",
                },
            },
            headerBgPart1: {
                zIndex: 0,
                left: 0,
                top: 0,
            },
            headerBgPart2: {

                left: 50,
                zIndex: 2,
                bottom: -30,
            },
            headerBgPart3: {
                display: 'none',
                top: 0,
                right: 0,
                zIndex: 2,
            },
            headerBgPart4: {
                right: "30%",
                bottom: -30,
            },
            root: {
                position: "relative",
                maxWidth: 1280,
                boxSizing: "border-box",
                margin: "100px auto",
                zIndex: 1,
                padding: "0 56px 64px",
                color: "white",
            },
            title: {
                marginBottom: 16,
                fontWeight: 700
            },
            subTitle: {
                fontSize: 22,
                fontWeight: 500,
                marginBottom: 16,
            },
            imgBlock: {
                width: "50%",
                zIndex: 4,
                height: '100%',
                position: 'absolute',
                right: 0,
                "& > img:first-child": {
                    position: "absolute",
                    top: 0,
                    zIndex: 3,
                    right: 40,
                    height: '100%',
                },
                "& > figure": {
                    position: "absolute",
                    right: 295,
                    top: -70,
                },
            },
            lOut: {
                alignItems: "center",
                "& > div": {
                    width: "auto",
                },
            },
            qr: {
                "& > img": {},
            },
            mobileLinks: {marginRight: 50},
            textBlock: {width: "50%", padding: "100px 0 180px 80px"},
            container: {
                position: "relative",
                zIndex: 3,
                backgroundColor: "#2D72E5",
                borderRadius: 24,
                width: "100%",
            },
            appStore: {
                marginRight: 22,
            },
            as: {
                marginBottom: 25,
                display: "inline-block",
            },
        },
        [theme.breakpoints.down("xs")]: {
            link: {
                maxWidth: 100,
            },
            title: {
                marginBottom: 16,

            },
            subTitle: {
                fontWeight: 500,
                marginBottom: 24,
            },
            container: {
                flexDirection: "column",
                position: 'relative',
                marginBottom: '85%'
            },
            textBlock: {
                textAlign: "center",
                height: 548,
            },
            lOut: {
                display: "none",
            },
            imgBlock: {
                width: "100%",
                position: 'absolute',
                top: '50%',
                "& > img": {
                    display: "none",
                },
            },
            qrMob: {
                display: 'flex',
                textAlign: "center",
                "& > img": {
                    display: "block",
                    margin: "0 auto",
                },

                "& > span": {
                    display: "inline-block",
                },
            },
        },
        link: {
            color: "#2D72E5",
            display: "block",
            textDecoration: "underline",
            cursor: "pointer",
            maxWidth: '100%',
            "& > img": {
                width: "100%",
            },
        },
    })
);

export const Geo = () => {
    const classes = useStyles({});
    const [t] = useTranslation()

    const [selectedGif, setSelectedGif] = useState<number>(1)
    const [sliderDirection, setSliderDirection] = useState<'back' | 'forward'>('back')
    const [isMount, setIsMount] = useState<boolean>(false)

    useEffect(() => {
        if (isMount) {
            if (sliderDirection === 'back') {
                setSelectedGif(prevState => --prevState)
            } else {
                setSelectedGif(prevState => ++prevState)
            }
        }
        setIsMount(true)
    }, [sliderDirection])


    const onPrevClick = () => {
        if(selectedGif!==1){
            if (sliderDirection === 'back') {
                setSelectedGif(prevState => --prevState)
            } else {
                setSliderDirection("back")
            }
        }

    }

    const onNextClick = () => {
        if(selectedGif!==16){
            if (sliderDirection === 'forward') {
                setSelectedGif(prevState => ++prevState)
            } else {
                setSliderDirection("forward")
            }
        }

    }

    return (
        <div className={classes.rootOutside}>
            <div className={`${classes.headerBgPart} ${classes.headerBgPart1}`}>
                <Parallax translateY={[120, 0]}>
                    <img src={process.env.PUBLIC_URL + "/appBgPart1.svg"} alt="app"/>
                </Parallax>
            </div>
            <div className={`${classes.headerBgPart} ${classes.headerBgPart2}`}>
                <Parallax translateY={[120, 0]}>
                    <img src={process.env.PUBLIC_URL + "/appBgPart2.svg"} alt="app"/>
                </Parallax>
            </div>
            <div className={`${classes.headerBgPart} ${classes.headerBgPart3}`}>
                <Parallax translateY={[120, -40]}>
                    <img src={process.env.PUBLIC_URL + "/appBgPart5.svg"} alt="app"/>
                </Parallax>
            </div>
            <div className={`${classes.headerBgPart} ${classes.headerBgPart4}`}>
                <Parallax translateY={[120, 0]}>
                    <img src={process.env.PUBLIC_URL + "/appBgPart4.svg"} alt="app"/>
                </Parallax>
            </div>
            <div className={classes.root}>
                <Grid
                    container
                    justify="space-between"
                    wrap="nowrap"
                    className={classes.container}
                >
                    <Grid item className={classes.textBlock}>

                        <BccTypography block type="h1" className={classes.title}>
                            {t('geo.title')}
                        </BccTypography>
                        <div className={classes.subTitle}>
                            {t('geo.description')}
                        </div>

                        <div
                            className={`geo-step-description ${selectedGif <= 6 ? 'simple-number' : 'not-simple-number'}`}>
                            <span className={'geo-step-description-text'}>
                                {t(`geo.steps.${selectedGif}`)}
                            </span>
                        </div>

                    </Grid>
                    <Grid item className={classes.imgBlock}>

                        <GifContainer sliderDirection={sliderDirection} selectedImage={selectedGif}
                                      onNextClick={onNextClick} onPrevClick={onPrevClick}/>


                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

interface GifContainerProps {
    onNextClick: () => void,
    onPrevClick: () => void,
    selectedImage: number,
    sliderDirection: 'back' | 'forward'
}

const GifContainer = ({onNextClick, onPrevClick, selectedImage, sliderDirection}: GifContainerProps) => {
    const {i18n} = useTranslation()

    const getImage = (num: number) => {
        return <img src={process.env.PUBLIC_URL + `/geo/${i18n.language}/${num}.png`}/>
    }

    return (
        <div className={'main-container'}>


            <img className={classNames('chevron', 'left', selectedImage === 1 ? 'disabled' : '')}
                 onClick={onPrevClick
                 }

                 src={`${process.env.PUBLIC_URL}/chevron-left.svg`}/>

            <div>
                <SwitchTransition>
                    <FadeTransition a={sliderDirection === 'forward'} key={selectedImage}>
                        <div className={'button-container'}>
                            {getImage(selectedImage)}
                        </div>
                    </FadeTransition>
                </SwitchTransition>


            </div>

            <img
                className={selectedImage === 16 ? 'chevron right disabled' : 'chevron right'}
                onClick={onNextClick}
                src={`${process.env.PUBLIC_URL}/chevron-right.svg`}
            />

        </div>

    )
}
