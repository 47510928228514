import React, {Suspense} from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import ReactGA from "react-ga";
import * as serviceWorker from "./serviceWorker";
import {initReactI18next} from "react-i18next";
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import Loading from "./components/Loading";
import {LocalStorageService} from "./local-storage.service";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-MJD5LQC",
};

TagManager.initialize(tagManagerArgs);

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        fallbackLng: LocalStorageService.getItem('lang') || 'kz',
        backend: {
            loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`,
        },
    });

ReactGA.initialize(process.env.REACT_APP_G_ANALYTICS || "");
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
    <Suspense fallback={(<Loading/>)}>
        <App/>
    </Suspense>, document.getElementById("root")
);

serviceWorker.unregister();
