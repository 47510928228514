import React from "react";
import './style.scss'
import {useTranslation} from "react-i18next";

const description = [
    "ticketon.description.1",
    "ticketon.description.2",
    "ticketon.description.3",
    "ticketon.description.4",
    "ticketon.description.5"

]
export const Ticketon = () => {
    const {t, i18n} = useTranslation()


    return (<div className={'ticketon-wrapper'}>
        <div className={'ticketon-container'}>
            <div className={'ticketon-info'}>
                <div className={'ticketon-title'}>
                    {t('ticketon.title')}
                </div>

                <div className={'ticketon-description'}>
                    <ul>
                        {description.map(desc => <li key={desc}>
                            {t(desc)}
                        </li>)}

                    </ul>
                </div>

                <div className={'ticketon-cashback-container'}>
                    <div className={'ticketon-cashback'}>
                        {t('ticketon.cashback')}
                    </div>

                    <img className={'cashback-percent-image'}
                         src={process.env.PUBLIC_URL + "/ticketon/cashback-percent.png"}/>
                    <img className={'cashback-percent-image-mob'}
                         src={process.env.PUBLIC_URL + "/ticketon/cashback-percent-mob.png"}/>

                </div>
            </div>
            <div className={'ticketon-img-container'}>
                <img className={'ticketon-img'}
                     src={process.env.PUBLIC_URL + `/ticketon/ticketon-${i18n.language}.gif`}/>

            </div>
        </div>

    </div>)
}