import React from "react";
import {ScrollWrapper} from "../../components/ScrollWrapper";
import {ParallaxProvider} from "react-scroll-parallax";
import {DepositInfoHeader} from "../deposit-info/components/header";
import {Footer} from "../../components";
import {Geo} from "./components/geo/geo";
import {Ticketon} from "./components/ticketon";
import {Gamefacation} from "./components/gamefacation";

export const NewFeaturesPage = ()=>{

    return (
        <ScrollWrapper>
            <ParallaxProvider>
                <DepositInfoHeader/>
                <Geo/>
                <Ticketon/>
                <Gamefacation/>
                <Footer/>
            </ParallaxProvider>
        </ScrollWrapper>
    )
}