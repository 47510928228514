import React from "react";
import "./App.css";
import {YMInitializer} from "react-yandex-metrika";
import {Route, Routes} from "react-router";
import {CustomRouter} from "./components/CustomRouter";
import {createBrowserHistory} from "history";
import {DepositInfoPage} from "./pages/deposit-info/deposit-info";
import {MainPage} from "./pages/main-page";
import {NewFeaturesPage} from "./pages/new-features";
import {ParentsTask} from "./pages/parents-task";
import {FinancialLessonsPage} from "./pages/financial-lessons";
import {IphonePromotionPage} from "./pages/new-page";
import {AccountRemovePage} from "./pages/account-remove-info";

export const history = createBrowserHistory()


function App() {


    return (
        <div className="root">
            <YMInitializer
                accounts={[65811811]}
                options={{
                    clickmap: true,
                    trackLinks: true,
                    accurateTrackBounce: true,
                    webvisor: true,
                    trackHash: true,
                }}
            />
                <CustomRouter history={history}>
                    <Routes>
                        <Route path={'/juniorbank'} element={<MainPage/>}/>
                        <Route path={'/juniorbank/deposit-info'} element={<DepositInfoPage/>}/>
                        <Route path={'/juniorbank/new-features'} element={<NewFeaturesPage/>}/>
                        <Route path={'/juniorbank/parents-task'} element={<ParentsTask/>}/>
                        <Route path={'/juniorbank/financial-lessons'} element={<FinancialLessonsPage/>}/>
                        <Route path={'/juniorbank/14iphone/promotion'} element={<IphonePromotionPage/>}/>
                        <Route path={'/juniorbank/account-remove'} element={<AccountRemovePage/>}/>


                    </Routes>
                </CustomRouter>
        </div>
    );
}




export default App;
