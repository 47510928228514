import {ParallaxProvider} from "react-scroll-parallax";
import {Footer} from "../../components";
import React from "react";
import {DepositConditions} from "./components/deposit-conditions";
import {Deposit} from "./components/deposit";
import {DepositInfoHeader} from "./components/header";

import { ScrollWrapper } from "../../components/ScrollWrapper";

export const DepositInfoPage = () => {
    return (
        <ScrollWrapper>
            <ParallaxProvider>
                <DepositInfoHeader/>
                <DepositConditions/>
                <Deposit/>
                <Footer/>
            </ParallaxProvider>
        </ScrollWrapper>
    )
}
